import { createWebHistory, createRouter } from "vue-router";
import Timer from "@/views/Timer.vue";
import TimerPartial from "@/views/TimerPartial.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Timer,
    meta: {
      title: "PVH Timer"
    }
  },
  {
    path: "/mrd",
    name: "mrd",
    component: TimerPartial,
    meta: {
      title: "PVH Timer"
    }
  },
  {
    path: "/mln",
    name: "mln",
    component: TimerPartial,
    meta: {
      title: "PVH Timer"
    }
  },
  {
    path: "/tho",
    name: "tho",
    component: TimerPartial,
    meta: {
      title: "PVH Timer"
    }
  },
  {
    path: "/hun",
    name: "hun",
    component: TimerPartial,
    meta: {
      title: "PVH Timer"
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ?? to.name;
  next();
});

export default router;
