<template>
  <div class="container min-vh-100 d-flex justify-content-center align-items-center">
    <div class="timer-section">
      <vue3-autocounter
          ref="counter"
          :autoinit="true"
          :decimals="0"
          :duration=duration
          :end-amount=endAmount
          :start-amount=currentAmount
          decimal-separator=""
          prefix=""
          separator=""
          suffix=""
          @finished=clearInterval()
      />
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import Vue3autocounter from 'vue3-autocounter';
import moment from 'moment';

export default defineComponent({
  name: 'HomePage',
  components: {
    'vue3-autocounter': Vue3autocounter
  },
  data() {
    return {
      part: 0,
      duration: 0,
      interval: 0,
      startAmount: 0,
      currentAmount: 0,
      endAmount: 0,
      endDate: {
        type: Date,
        required: false,
      },
      startDate: {
        type: Date,
        required: false,
      }
    }
  },
  beforeMount() {

    this.endAmount = Number(process.env.VUE_APP_ENDAMOUNT);
    this.startAmount = Number(process.env.VUE_APP_STARTAMOUNT);

    this.startDate = process.env.VUE_APP_STARTDATE;
    this.endDate = process.env.VUE_APP_ENDDATE;

    this.currentAmount = this.calculateCurrentAmount()
    this.duration = this.calculateDuration();
  },
  beforeUnmount() {
    this.clearInterval();
  },
  methods: {
    clearInterval() {
      clearInterval(this.interval);
    },
    getValueFromCounter(value, start, length) {
      return value.toString().substring(start, start + length);
    },
    calculateDuration() {
      let now = moment.utc();
      let end = moment(this.endDate);
      let diff = end.diff(now, "seconds");


      console.log(now.toString());
      console.log(end.toString());

      if (diff < 0) {
        return 0.1
      } else {
        return end.diff(now, "seconds");
      }
    },
    refreshPage() {
      this.interval = setInterval(function () {
        location.reload();
      }.bind(this), 50000);
    },
    calculateCurrentAmount() {
      let start = moment(this.startDate);
      let end = moment(this.endDate);
      let now = moment.utc();

      let totalSeconds = end.diff(start, "seconds");
      let pastSeconds = now.diff(start, "seconds");
      let currentAmount = this.startAmount + (((this.endAmount - this.startAmount) / totalSeconds) * pastSeconds);

      return Number(currentAmount);

    },
  }
});
</script>
<style lang="scss" scoped>
.timer-section {
  span {
    font-size: min(15vw, 35rem);
    font-weight: $font-weight-bold;
    line-height: 1;
  }
}
</style>
